import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import MembershipPlans from "./MembershipPlans";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const MembershipDialog: React.FC<Props> = (props) => {
  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: "82px", md: "48px" },
              paddingBottom: { xs: "58px", md: "48px" },
              paddingX: { xs: "0px", md: "58px" },
              height: { xs: "fit-content", md: "80vh" },
              maxHeight: { xs: "95vh", md: "907px" },
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              overflowY: "scroll",
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              maxWidth: { xs: "100vw", md: "auto" },
            }}
          >
            {/* Dialog content */}
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: { xs: "24px", md: "40px" },
                top: { xs: "24px", md: "40px" },
                cursor: "pointer",
                color: "white",
              }}
            />
            <MembershipPlans membershipCardsGap="12px" />
          </Box>
        </Box>
      </>
    )
  );
};

export default MembershipDialog;
