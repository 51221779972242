import React from "react";
import { IconColor } from "./interface/ColorInterface";

export const CameraIconSvg: React.FC<IconColor> = ({
  color,
  width = 24,
  height = 24,
}) => (
  <svg
    width={width || "64"}
    height={height || "64"}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="34.666"
      r="8"
      stroke={color || "#3D3D3D"}
      stroke-width="2"
    />
    <path
      d="M26.0728 56H37.9246C46.2474 56 50.4087 56 53.398 54.0389C54.6922 53.1899 55.8033 52.099 56.668 50.8285C58.6654 47.8935 58.6654 43.8078 58.6654 35.6364C58.6654 27.4649 58.6654 23.3792 56.668 20.4443C55.8033 19.1737 54.6922 18.0828 53.398 17.2338C51.4772 15.9737 49.0725 15.5233 45.3907 15.3623C43.6338 15.3623 42.121 14.0551 41.7765 12.3636C41.2596 9.82637 38.9906 8 36.3551 8H27.6423C25.0068 8 22.7378 9.82637 22.2209 12.3636C21.8764 14.0551 20.3636 15.3623 18.6067 15.3623C14.9249 15.5233 12.5202 15.9737 10.5993 17.2338C9.30524 18.0828 8.19412 19.1737 7.32943 20.4443C5.33203 23.3792 5.33203 27.4649 5.33203 35.6364C5.33203 43.8078 5.33203 47.8935 7.32943 50.8285C8.19412 52.099 9.30524 53.1899 10.5993 54.0389C13.5887 56 17.75 56 26.0728 56Z"
      stroke={color || "#3D3D3D"}
      stroke-width="2"
    />
    <path
      d="M50.6667 26.666H48"
      stroke={color || "#3D3D3D"}
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);
