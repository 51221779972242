import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const TermsOfUse = (props: Props) => {
  const TermsOfUseContent = [
    {
      title: "Acceptance of Terms",
      paragraphs: [
        `By using UI Wiki, you agree to be bound by these Terms of Use and our [Privacy Policy]. These terms may be updated periodically, and continued use of the Platform signifies your acceptance of any modified terms.`,
      ],
    },
    {
      title: "Eligibility",
      paragraphs: [
        `The Platform is intended for users aged 18 and above. By using the Platform, you confirm that you are 18 years of age or older, or have obtained parental or legal guardian consent to use the Platform.`,
      ],
    },
    {
      title: "Account Registration",
      paragraphs: [
        `To access certain features, you may need to create an account with accurate and complete information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.`,
      ],
    },
    {
      title: "Subscription and Payment",
      paragraphs: [
        `UI Wiki operates on a subscription-based model. By subscribing, you gain access to our library of design components. You agree to provide current, accurate payment information, and you authorize us to charge your account for the selected subscription plan. Subscription fees are non-refundable, except where required by law.`,
      ],
    },
    {
      title: "Use of Platform Content",
      paragraphs: [
        `You may use, modify, and integrate the components provided by UI Wiki ("Content") in your projects, provided you adhere to the licensing terms. You may not resell, redistribute, or make available any Content as standalone assets or use them in a way that violates our [Licensing Agreement].`,
      ],
    },
    {
      title: "Intellectual Property",
      paragraphs: [
        `All content on the Platform, including designs, text, graphics, logos, and software, is the property of UI Wiki or its content creators. You may not copy, modify, distribute, or sell any part of the Platform without our prior written permission.`,
      ],
    },
    {
      title: "Prohibited Conduct",
      paragraphs: [
        `By using the Platform, you agree not to:`,
        `Use the Platform in a way that violates applicable laws or regulations.`,
        `Attempt to interfere with or disrupt the Platform’s operation or security.`,
        `Use any automated means to access the Platform without permission.`,
        `Engage in fraudulent, deceptive, or harmful activities on the Platform.`,
      ],
    },
    {
      title: "Termination of Use",
      paragraphs: [
        `UI Wiki reserves the right to terminate or suspend access to the Platform without notice if you violate these Terms or if your actions threaten the security or integrity of the Platform.`,
      ],
    },
    {
      title: "Disclaimers",
      paragraphs: [
        `UI Wiki provides the Platform "as is" without any warranty of any kind, express or implied. We do not warrant that the Platform will be error-free, uninterrupted, or meet your expectations. Use of the Platform is at your sole risk.`,
      ],
    },
    {
      title: "Limitation of Liability",
      paragraphs: [
        `To the maximum extent permitted by law, UI Wiki and its affiliates are not liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use or inability to use the Platform.`,
      ],
    },
    {
      title: "Indemnification",
      paragraphs: [
        `You agree to indemnify and hold UI Wiki, its affiliates, and employees harmless from any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of your use of the Platform or violation of these Terms.`,
      ],
    },
    {
      title: "Governing Law",
      paragraphs: [
        `These Terms shall be governed by the laws of India, without regard to conflict of law principles.`,
      ],
    },
    {
      title: "Changes to Terms",
      paragraphs: [
        `UI Wiki reserves the right to update these Terms at any time. Changes will be effective upon posting to the Platform. It is your responsibility to review these Terms periodically. Your continued use of the Platform after any modifications signifies acceptance of the updated Terms.`,
      ],
    },
    {
      title: "Contact Information",
      paragraphs: [
        `For questions or concerns regarding these Terms of Use, please contact us at contact@uiwiki.co.`,
      ],
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "40px", md: "80px" },
          alignItems: "center",
          gap: { xs: "32px", sm: "60px" },
          width: "100%",
          maxWidth: "862px",
        }}
      >
        {/* -------Header Section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start", sm: "center" },
            textAlign: { xs: "start", sm: "center" },
            gap: "16px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "60%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Terms Of Use
          </Typography>
        </Box>

        {/* -------Content section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "24px", sm: "32px" },
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "18px",
                color: "white",
                fontWeight: "600",
              }}
            >
              UI Wiki Terms of Use
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.main", fontWeight: "400", mb: "18px" }}
            >
              Effective Date: 15 November 2024
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.5)",
                lineHeight: "24px",
              }}
            >
              These Terms of Use ("Terms") govern your access to and use of UI
              Wiki’s website and services (collectively, the "Platform"). By
              accessing or using the Platform, you agree to comply with these
              Terms. If you do not agree to these Terms, please refrain from
              using the Platform.
            </Typography>
          </Box>
          {TermsOfUseContent?.map((section: any, index: number) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                style={{
                  marginBottom: "12px",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {section.title}
              </Typography>
              {section?.paragraphs?.map((paragraph: string, pIndex: number) => (
                <Typography
                  variant="subtitle1"
                  key={pIndex}
                  style={{
                    marginBottom:
                      pIndex === section.paragraphs.length - 1 ? "0" : "24px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    lineHeight: "24px",
                  }}
                >
                  {paragraph}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
        <Typography
          variant="subtitle1"
          style={{
            marginBottom: "24px",
            fontWeight: "400",
            color: "rgba(255, 255, 255, 0.5)",
            lineHeight: "24px",
          }}
        >
          By using UI Wiki, you acknowledge that you have read, understood, and
          agree to these Terms of Use.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsOfUse;
