import React, { useEffect, useState } from "react";
import ActiveMembershipCard from "./ActiveMembershipCard";
import { Box, Typography } from "@mui/material";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import { RainIcon } from "../../../../assets/icons/icons";
import MembershipDialog from "./membershipDialog/MembershipDialog";
import {
  getUserActiveSubscription,
  getUserSubscriptions,
} from "../../../../services/component";
import { useGlobalContext } from "../../../../App";
import DefaultLoading from "../../../../shared/Loading/DefaultLoading";

type Props = {};

const Membership = (props: Props) => {
  const { userDetails } = useGlobalContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [membershipDialog, setMembershipDialog] = useState<boolean>(false);
  const [activeMembership, setActiveMembership] = useState<any>();
  //   {
  //   type: "Regular monthly membership",
  //   price: 10,
  //   features: [
  //     {
  //       title: "Components",
  //       list: ["Premium components 40+"],
  //     },
  //     {
  //       title: "Apps and Tools",
  //       list: ["Chrome Extension", "Figma Plugin"],
  //     },
  //     {
  //       title: "Other",
  //       list: ["Priority Support", "Discount to partner tools"],
  //     },
  //   ],
  // }

  const handleCloseMembershipDialog = () => {
    setMembershipDialog(false);
  };
  const handleRefresh = () => {
    setActiveMembership("");
  };

  const fetchUserSubsctiptions = async () => {
    await getUserActiveSubscription(userDetails?.id)
      .then((res: any) => {
        setActiveMembership(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUserSubsctiptions();
  }, []);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "24px", sm: "62px 56px 48px 56px" },
        minWidth: { xs: "100%", sm: "460px" },
        maxWidth: { xs: "100%", sm: "460px" },
        height: "70vh",
        backgroundColor: "rgba(255, 255, 255, 0.06)",
        borderRadius: "14px",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "24px", height: "24px" }}>
        <DefaultLoading />
      </Box>
    </Box>
  ) : activeMembership ? (
    <ActiveMembershipCard
      handleRefresh={handleRefresh}
      data={activeMembership}
      setMembershipDialog={setMembershipDialog}
    />
  ) : (
    <Box sx={{ width: "100%" }}>
      {membershipDialog && (
        <MembershipDialog
          handleDialog={handleCloseMembershipDialog}
          activeDialog={membershipDialog}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "32px 24px", sm: "62px 56px 48px 56px" },
          minWidth: { xs: "100%", sm: "460px" },
          maxWidth: { xs: "100%", sm: "460px" },
          backgroundColor: "rgba(255, 255, 255, 0.06)",
          borderRadius: "14px",
          alignItems: "center",
          gap: { xs: "42px", sm: "73px" },
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            width: "100%",
            color: "white",
          }}
          variant="h4"
        >
          Select your plan here
        </Typography>

        <Box
          sx={{
            width: "270px",
            height: "270px",
            aspectRatio: "1/1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageContainer title="rain">{RainIcon}</ImageContainer>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "white",
              width: "80%",
            }}
            variant="h5"
          >
            I can{" "}
            <span
              style={{
                color: "#CCFF00",
              }}
            >
              smile
            </span>
            , but you need to select my plan first
          </Typography>

          <ButtonPrimary
            sx={{
              width: "100%",
              maxWidth: "348px",
            }}
            onClick={() => {
              setMembershipDialog(true);
            }}
            label="Choose plan"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Membership;
