import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import { CameraIconSvg } from "../../../../assets/icons/CameraIconSvg";
import { PlaceholderAvatar } from "../../../../assets/images/image";
import Dropzone, { DropzoneRef } from "react-dropzone";
import AvatarDialog from "./AvatarDialog";
import { uploadDocument } from "../../../../services/component";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { useGlobalContext } from "../../../../App";
import { updateUserDetails } from "../../../../services/user";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const ProfileDialog: React.FC<Props> = (props) => {
  const { userDetails } = useGlobalContext();
  const dropZoneRef = useRef<DropzoneRef | null>(null);
  useEffect(() => {}, [props?.activeDialog]);

  const [selectedProfile, setSelectedProfile] = useState<any>("");
  const [selectedAvatar, setSelectedAvatar] = useState<any>("");
  const [activeAvatarDialog, setActiveAvatarDialog] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const onFileDrop = async (file: any) => {
    setSelectedProfile(file[0]);
  };

  const handleChangeAvatar = (data?: any) => {
    if (data) {
      setSelectedAvatar(data);
    }
    setActiveAvatarDialog(false);
  };

  const handleSaveAvatar = async () => {
    setLoading(true);
    let uploadedProfile: any = "";
    if (selectedProfile) {
      await uploadDocument(selectedProfile)
        .then((res: any) => {
          uploadedProfile = res?.data[0];
        })
        .catch(() => {
          toastError(
            "Ops! Something went wrong.",
            "Failed uploading the user profile."
          );
        });
    }

    await updateUserDetails({
      userId: userDetails?.id,
      avatar: uploadedProfile?.url || userDetails?.avatar,
      secondaryAvatar: selectedAvatar?.value || userDetails?.secondaryAvatar,
    })
      .then((res: any) => {
        toastSuccess(
          "Successfully updated the user details.",
          "User Pofile is successfully updated."
        );
        props?.handleDialog({
          profile: uploadedProfile?.previewURL,
          avatar: selectedAvatar?.value,
        });
      })
      .catch((err: any) => {
        toastError(
          "Ops! Something went wrong.",
          "Failed uploading the user details."
        );
      });
    setLoading(false);
  };
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          {activeAvatarDialog ? (
            <AvatarDialog
              handleDialog={handleChangeAvatar}
              activeDialog={activeAvatarDialog}
              activeId={selectedAvatar?.key}
            />
          ) : (
            <Box
              sx={{
                width: "588px",
                maxWidth: "588px",
                padding: "40px",
                height: "auto",
                bgcolor: "#1B1B1B",
                borderRadius: "24px",
                border: "1px solid #3D3D3D",
                cursor: "default",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "62px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "60px",
                  }}
                >
                  {/* -----Upload Profile image----- */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                        bgcolor: "background.default",
                        position: "relative",
                        width: "144px",
                        height: "144px",
                      }}
                    >
                      <Dropzone
                        accept={{
                          "image/png": [".png"],
                          "image/jpeg": [".jpeg", ".jpg"],
                        }}
                        ref={dropZoneRef}
                        disabled={false}
                        multiple={false}
                        onDrop={(file: any) => {
                          onFileDrop(file);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flex: 1,
                              cursor: "pointer",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            {selectedProfile || userDetails?.avatar ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",

                                  backgroundImage:
                                    selectedProfile instanceof File
                                      ? `url(${URL.createObjectURL(
                                          selectedProfile
                                        )})`
                                      : userDetails?.avatar &&
                                        `url(${userDetails?.avatar})`,
                                  flex: 1,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "50%",
                                  position: "relative",
                                  "&:hover .overlay": {
                                    opacity: 1, // Make the overlay visible on hover
                                  },
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <CameraIconSvg width="64px" height="64px" />
                              </Box>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </Box>

                    <Typography
                      sx={{ color: "rgba(255, 255, 255, 0.5)" }}
                      variant="body1"
                    >
                      Upload Photo
                    </Typography>
                  </Box>

                  {/* -----Choose Avatar----- */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box
                      onClick={() => {
                        setActiveAvatarDialog(true);
                      }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        bgcolor: "background.default",
                        position: "relative",
                        width: "144px",
                        height: "144px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          width: "140px",
                          height: "140px",
                          borderRadius: "50%",
                        }}
                        typeof="file"
                        alt="profile"
                        src={
                          selectedAvatar?.value ||
                          userDetails?.secondaryAvatar ||
                          PlaceholderAvatar
                        }
                      />
                    </Box>

                    <Typography
                      sx={{ color: "rgba(255, 255, 255, 0.5)" }}
                      variant="body1"
                    >
                      Choose Avatar
                    </Typography>
                  </Box>
                </Box>

                {/* -----Action buttons container----- */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <ButtonPrimary
                    isLoading={loading}
                    onClick={() => {
                      handleSaveAvatar();
                    }}
                    sx={{ width: "201px", height: "52px" }}
                    label="Save"
                  />
                  <ButtonSecondary
                    onClick={() => {
                      props?.handleDialog();
                    }}
                    sx={{ width: "201px", height: "52px" }}
                    label="Cancel"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    )
  );
};

export default ProfileDialog;
