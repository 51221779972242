import axios from "axios";
import { HOST_NAME } from "../utils/constants";
import Cookies from "js-cookie";

type LoginData = {
  username: string;
  password: string;
};
type RegisterData = {
  email: string;
  password: string;
  firstName: string;
  lastName?: string;
};

export const capturePayment = async (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/capture-payment`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const captureLifetimePayment = async (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/capture-lifetime-payment`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createPayment = async (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/create-payment`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginUser = async (data: LoginData) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const registerUser = async (data: RegisterData) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = async (data: any, id: string) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/reset-password/${id}`,
      headers: {},
      data: data,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
