import React from "react";
import { IconColor } from "./interface/ColorInterface";

export const CrownIconSvg: React.FC<IconColor> = ({
  color,
  width,
  height,
  transform,
}) => (
  <svg
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4623 12.938L15.1737 14.1896C15.1147 14.445 14.8874 14.6255 14.6253 14.6255H3.37529C3.11317 14.6255 2.88592 14.445 2.82686 14.1896L2.53829 12.938H15.4623ZM16.8612 6.87708L15.7222 11.813H2.27842L1.13936 6.87708C1.08929 6.65939 1.17254 6.43214 1.35198 6.29883C1.53198 6.16552 1.77329 6.15146 1.96679 6.26227L5.44811 8.25183L8.53173 3.62639C8.63242 3.47564 8.79892 3.38227 8.97948 3.37552C9.16173 3.36764 9.33329 3.44977 9.44411 3.59264L13.0604 8.24227L16.0006 6.28252C16.1924 6.15539 16.4427 6.15652 16.6323 6.28758C16.8224 6.41864 16.9124 6.65264 16.8612 6.87708Z"
      fill={color || "white"}
    />
  </svg>
);
