import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useSettingsContext } from "../Setting";
import LoadingIndicator from "../../../shared/Loading/LoadingIndicator";
import Profile from "../tabs/profile/Profile";
import Security from "../tabs/security/Security";
import Membership from "../tabs/membership/Membership";
import Payment from "../tabs/payment/Payment";
import Billing from "../tabs/billing/Billing";
import Notifications from "../tabs/notifications/Notifications";
import DefaultLoading from "../../../shared/Loading/DefaultLoading";

const SettingsRoutes: React.FC = () => {
  const theme = useTheme();
  const { activeTab } = useSettingsContext();
  const [showLoading, setShowLoading] = useState(false);

  return (
    <Box
      className="HideScrollbar"
      display="flex"
      // width="100%"
      maxWidth="100%"
      // sx={{ padding: "48px 56px" }}
    >
      {showLoading ? (
        <Box
          sx={{
            width: "42px",
            height: "42px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DefaultLoading />
        </Box>
      ) : (
        <Box width="100%">
          <Box
            sx={{
              // padding: "24px",
              width: "100%",
            }}
          >
            {activeTab === 1 ? (
              <Profile />
            ) : activeTab === 2 ? (
              <Security />
            ) : activeTab === 3 ? (
              <Membership />
            ) : activeTab === 4 ? (
              <Payment />
            ) : activeTab === 5 ? (
              <Billing />
            ) : (
              activeTab === 6 && <Notifications />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettingsRoutes;
