import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import UseRadioGroup from "../../shared/components/RadioInput";
import TextAreaInput from "../../shared/components/TextAreaInput";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { createComponentReport } from "../../services/component";
import { useGlobalContext } from "../../App";
import { toastError, toastSuccess } from "../../utils/ToastMessage";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  data: any;
};

const ReportDialog: React.FC<Props> = (props) => {
  const { userDetails } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const [reason, setReason] = useState<any>("");
  const [description, setDescription] = useState<string>("");

  const handleSubmitReport = async () => {
    setLoading(true);
    await createComponentReport({
      title: reason,
      description: description,
      userId: userDetails?.id,
      componentId: props?.data?.id,
    })
      .then((res: any) => {
        toastSuccess("Successfully submit.", "Successfully submit the report.");
      })
      .catch((err: any) => {
        toastError("Ops! Something went wrong.", "Failed to submit report.");
      });
    setLoading(false);
    props?.handleDialog();
  };

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              width: { xs: "90vw", md: "777px" },
              maxWidth: "777px",
              padding: { xs: "24px", md: "40px" },
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mb: "32px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <AlertTriangleIconSvg color="#CCFF00" />
                  <Typography variant="h4" sx={{ color: "text.secondary" }}>
                    Report a problem
                  </Typography>
                </Box>

                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: { xs: "24px", md: "40px" },
                    top: { xs: "24px", md: "40px" },
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "400", color: "rgba(255, 255, 255, 0.5)" }}
                >
                  Select a reason to Report this
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <UseRadioGroup
                    style={{
                      flexDirection: "column",
                    }}
                    radioInupStyle={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "start",
                      width: "100%",
                      border: "none",
                    }}
                    radioValues={[
                      "Irrelevent Result/incorrect Tags",
                      "Copyright/Trademark Violation",
                      "Wrong file or quality issue after downloading",
                      "Other",
                    ]}
                    radiosCaptions={[
                      "Irrelevent Result/incorrect Tags",
                      "Copyright/Trademark Violation",
                      "Wrong file or quality issue after downloading",
                      "Other",
                    ]}
                    value={reason}
                    onChange={(e: any) => {
                      setReason(e);
                    }}
                  />
                </Box>

                <Box>
                  <TextAreaInput
                    textinputstyles={{ gap: "12px" }}
                    inputStyles={{
                      height: "206px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      outline: "none",
                    }}
                    placeholderStyle={{ fontSize: "16px", lineHeight: "24px" }}
                    value={description}
                    onChange={(e: any) => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Describe your issue briefly"
                    label="Description"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <ButtonSecondary
                    onClick={(e) => {
                      e.stopPropagation();
                      props?.handleDialog();
                    }}
                    sx={{
                      height: "52px",
                      width: "201px",
                    }}
                    label="Cancel"
                  />
                  <ButtonPrimary
                    sx={{
                      height: "52px",
                      width: "201px",
                    }}
                    label="Submit"
                    isLoading={loading}
                    onClick={() => {
                      handleSubmitReport();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ReportDialog;
