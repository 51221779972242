import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

interface tableRowProps {
  row: any;
  handleRefreshData: any;
}

const handleCopyFigmaCode = (data: any) => {
  const htmlContent = data.figmaCode;
  // Create a Blob with the HTML content and specify the MIME type as 'text/html'
  const blob = new Blob([htmlContent], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });
  // Copy the Blob to the clipboard
  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      //content copied
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const Row: React.FC<tableRowProps> = ({ row, handleRefreshData }) => {
  return (
    <TableRow
      onClick={() => {
        handleCopyFigmaCode(row);
      }}
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.06) !important",
        },
      }}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.id}
    >
      <TableCell
        sx={{
          width: "fit-content",
          minWidth: "153px",
          maxWidth: "153px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          ></Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "fit-content",
          minWidth: "153px",
          maxWidth: "153px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          ></Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "fit-content",
          minWidth: "153px",
          maxWidth: "153px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          ></Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "fit-content",
          minWidth: "153px",
          maxWidth: "153px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          ></Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
