import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DocumentIconSvg } from "../../assets/icons/DocumentIconSvg";
import { downloadPDF } from "../../utils/extensions";
import {
  LifetimeSubscription,
  MonthlySubscription,
  YearlySubscription,
} from "../../assets/images/image";

type Props = {
  data: any;
};

const PurchaseCard = (props: Props) => {
  const planType = props?.data?.planType;
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleDownloadInvoice = (url: string) => {
    downloadPDF(url);
  };

  useEffect(() => {
    if (planType === "MONTHLY") {
      setImageUrl(MonthlySubscription);
      setTitle("Monthly Access Pass");
      setDescription("Single user license");
    } else if (planType === "YEARLY") {
      setImageUrl(YearlySubscription);
      setTitle("Yearly Access Pass");
      setDescription("Single user license");
    } else if (planType === "LIFETIME") {
      setImageUrl(LifetimeSubscription);
      setTitle("Lifetime Access Pass");
      setDescription("Single user license");
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          width: "100%",
          gap: "24px",
        }}
      >
        {/* -----Image container----- */}
        <Box
          sx={{
            maxWidth: "180px",
            maxHeight: "120px",
            width: "100%",
            height: "100%",
            aspectRatio: "3/2",
            borderRadius: "10px",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              aspectRatio: "3/2",
              borderRadius: "10px",
            }}
            alt="cover-image"
            src={imageUrl}
          />
        </Box>

        {/* ------Details Section------ */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                height: "100%",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#FFFFFF",
                  }}
                  variant="subtitle1"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  variant="body1"
                >
                  {description}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  boxShadow: "inset 0px 0px 0px 1px rgba(255, 255, 255, 0.06)",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
                onClick={() => handleDownloadInvoice(props?.data?.invoiceUrl)}
              >
                <DocumentIconSvg />
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              fontWeight: "600",
              color: "white",
            }}
            variant="subtitle1"
          >
            ${parseInt(props?.data?.amount)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseCard;
