import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import { DashboardIconSvg } from "../../assets/icons/DashboardIconSvg";
import { LayersIconSvg } from "../../assets/icons/LayersIconSvg";
import { MessageIconSvg } from "../../assets/icons/MessageIconSvg";

export const mainNavigation: any = [
  {
    text: "Explore",
    module: "EXPLORE",
    icon: <DashboardIconSvg />,
    iconSelected: <DashboardIconSvg color="white" />,
    to: "/search",
  },
  // {
  //   text: "Product",
  //   module: "PRODUCT",
  //   icon: <LayersIconSvg />,
  //   iconSelected: <LayersIconSvg color="white" />,
  //   to: "/product",
  // },
  {
    text: "FAQs",
    module: "FAQ",
    icon: <AlertTriangleIconSvg />,
    iconSelected: <AlertTriangleIconSvg color="white" />,
    to: "/faq",
  },
  {
    text: "Pricing",
    module: "PRICING",
    icon: <MessageIconSvg />,
    iconSelected: <MessageIconSvg color="white" />,
    to: "/pricing",
  },
];
