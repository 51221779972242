import React from "react";
import { IconColor } from "./interface/ColorInterface";

export const MagicStarIconSvg: React.FC<IconColor> = ({
  color,
  width,
  height,
  transform,
  opacity,
}) => (
  <svg
    width={width || "16"}
    height={height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5281 2.76065L11.4815 5.2873C11.4748 5.63397 11.6948 6.09398 11.9748 6.30064L13.6281 7.55396C14.6881 8.35396 14.5148 9.33397 13.2481 9.73397L11.0948 10.4073C10.7348 10.5206 10.3548 10.914 10.2615 11.2806L9.74814 13.2406C9.34148 14.7873 8.32813 14.9406 7.48813 13.5806L6.31479 11.6806C6.10146 11.334 5.5948 11.074 5.1948 11.094L2.96815 11.2073C1.37482 11.2873 0.921481 10.3673 1.96148 9.15398L3.28147 7.62063C3.52813 7.33397 3.64146 6.80064 3.52813 6.44064L2.85483 4.2873C2.4615 3.02063 3.16816 2.32064 4.42816 2.73397L6.39483 3.38064C6.72816 3.48731 7.22815 3.41397 7.50815 3.2073L9.5615 1.7273C10.6682 0.927301 11.5548 1.39398 11.5281 2.76065Z"
      fill={color || "white"}
      fill-opacity={opacity || "0.12"}
    />
  </svg>
);
