import { Box } from "@mui/material";
import React from "react";

type Props = {
  data: any;
  selectedAvatar?: any;
  handleSelectAvatar: any;
  activeId?: any;
};

const Avatar = (props: Props) => {
  return (
    <Box
      onClick={() => {
        props?.handleSelectAvatar(props?.data);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "140px",
        height: "140px",
        borderRadius: "50%",
        cursor: "pointer",
        boxShadow:
          props?.selectedAvatar !== ""
            ? props?.selectedAvatar?.key === props?.data?.key
              ? "inset 0px 0px 0px 4px #CCFF00"
              : "none"
            : props?.activeId === props?.data?.key
            ? "inset 0px 0px 0px 4px #CCFF00"
            : "none",
      }}
    >
      <img
        style={{
          width: "136px",
          height: "136px",
          borderRadius: "50%",
        }}
        alt="avatar"
        src={props?.data?.value}
      />
    </Box>
  );
};

export default Avatar;
