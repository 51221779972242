import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import Dropdown from "../../../../../shared/components/Dropdown";
import { createStyles, makeStyles } from "@mui/styles";
import TextAreaInput from "../../../../../shared/components/TextAreaInput";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { colors } from "../../../../../utils/colors";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const ConfirmCancelMembershipDialog: React.FC<Props> = (props) => {
  const styles = useStyles();

  const [reason, setReason] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          {/* ------Dialog card------ */}
          <Box
            sx={{
              width: { xs: "90vw", sm: "588px" },
              maxWidth: { xs: "90vw", sm: "588px" },
              padding: { xs: "24px", sm: "40px" },
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: { xs: "24px", sm: "38px" },
                top: { xs: "24px", sm: "40px" },
                cursor: "pointer",
                color: "white",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "primary.main",
                    fontWeight: "600",
                    width: "100%",
                  }}
                >
                  Cancel Plan
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    fontWeight: "500",
                    width: "100%",
                  }}
                >
                  Sorry to see you go :(
                </Typography>
              </Box>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              <Box>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownPlaceholder={styles.placeholder}
                  label="What is your reason for cancelling?"
                  value={reason}
                  placeholder="Select a reason"
                  setValue={setReason}
                  options={[
                    "Found a better plan elsewhere",
                    "No longer need the service",
                    "Difficult to use",
                    "Not meeting expectations",
                    "Other",
                  ]}
                />
              </Box>

              <Box>
                <TextAreaInput
                  inputStyles={{
                    height: "206px",
                    width: "100%",
                  }}
                  value={feedback}
                  onChange={(e: any) => {
                    setFeedback(e.target.value);
                  }}
                  placeholder="Share your feedback here..."
                  setValue={setFeedback}
                />
              </Box>

              {/* ------Action buttons container------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  gap: "12px",
                  justifyContent: "center",
                }}
              >
                <ButtonPrimary
                  onClick={() => {
                    props?.handleDialog();
                  }}
                  sx={{
                    width: { xs: "100%", sm: "174px" },
                  }}
                  label="Stay subscribed"
                />

                <ButtonPrimary
                  onClick={() => {
                    props?.handleDialog({
                      reason: reason,
                      feedback: feedback,
                      action: "CANCEL",
                    });
                  }}
                  sx={{
                    bgcolor: colors.primaryRed,
                    width: { xs: "100%", sm: "244px" },
                    ":hover": {
                      bgcolor: colors.primaryRed,
                    },
                  }}
                  LabelStyle={{
                    color: "white",
                  }}
                  label="Cancel my subscription"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ConfirmCancelMembershipDialog;

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    placeholder: {
      color: "#A0AEC0",
      fontSize: "14px",
      fontWeight: "300 !important",
      fontFamily: "Outfit",
    },
    inputContainer: {
      flex: 1,
      maxWidth: "408px",
      width: "100%",
    },
    statusContainer: {
      flex: 1,
      maxWidth: "235px",
      width: "100%",
    },
  })
);
