import { Box, Typography } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import SettingsSidenavigation from "./settingsSidenavigation/SettingsSidenavigation";
import SettingsRoutes from "./settingsSidenavigation/SettingsRoutes";

interface SettingsContextType {
  activeTab: any;
  setActiveTab: any;
}
export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);
export const useSettingsContext = (): any => useContext(SettingsContext)!;
type Props = {};

const Setting = (props: Props) => {
  const [activeTab, setActiveTab] = useState(1);

  const settingsContextProps = {
    activeTab,
    setActiveTab,
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingY: { xs: "24px", sm: "40px", md: "80px" },
        alignItems: "center",
        gap: { xs: "32px", md: "80px" },
        width: "100%",
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "16px",
          width: "100%",
          paddingX: { xs: "24px", sm: "40px", md: "80px" },
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: "58px",
            lineHeight: "110%",
            background:
              "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h1"
        >
          Account Settings
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: "400" }}
        >
          Manage your profile, security, payment and notification settings.
        </Typography>
      </Box>

      {/* ------Setting tab pannel------ */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <SettingsContext.Provider value={settingsContextProps}>
          <Box
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              gap: { xs: "32px", md: "48px" },
              minWidth: "100%",
            }}
            display="flex"
            justifyContent="center"
            width="100%"
            maxWidth="100%"
          >
            <SettingsSidenavigation />

            {/* Details */}
            <Box sx={{ paddingX: { xs: "24px", sm: "40px", md: "0px" } }}>
              <SettingsRoutes />
            </Box>
          </Box>
        </SettingsContext.Provider>
      </Box>
    </Box>
  );
};

export default Setting;
