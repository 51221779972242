import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSettingsContext } from "../Setting";
import { ArrowRightIcon } from "../../../assets/icons/ArrowRightIcon";
import { useEffect, useRef } from "react";

export const SettingsNavigationListItems = () => {
  const theme = useTheme();
  const carouselRef: any = useRef(null);

  const breakpoint_down_960 = useMediaQuery(theme.breakpoints.down(960));
  const { activeTab, setActiveTab } = useSettingsContext();

  useEffect(() => {}, [activeTab]);
  return (
    <List
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        gap: "16px",
        "&.MuiList-root": {
          paddingY: "0px !important",
        },
      }}
    >
      {breakpoint_down_960 ? (
        <Box
          className="HideScrollbar"
          ref={carouselRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            gap: { xs: "16px", md: "24px" },
            padding: { xs: "0px 24px", sm: "0px", md: "0px 24px" },
            width: "100%",
          }}
        >
          {[
            {
              id: 1,
              text: "Profile",
              badgeText: null,
            },
            {
              id: 2,
              text: "Security",
              badgeText: null,
            },
            {
              id: 3,
              text: "Membership",
              badgeText: null,
            },
          ].map((menuItem: any, index: number) => {
            return (
              <ListItem
                onClick={() => {
                  setActiveTab(menuItem?.id);
                }}
                key={menuItem?.id}
                sx={{
                  paddingX: "0px",
                  display: "flex",
                  width: "100%",
                  minWidth:
                    index === 0 ? "117px" : index === 1 ? "130px" : "191px",
                  justifyContent: "space-between",
                  bgcolor:
                    activeTab === menuItem?.id
                      ? "primary.main"
                      : "rgba(255, 255, 255, 0.06)",
                  boxShadow:
                    activeTab === menuItem?.id
                      ? "none"
                      : "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                  "&.MuiListItem-root": {
                    paddingY: "0px !important",
                  },
                }}
              >
                <ListItemButton
                  disableRipple
                  sx={{
                    height: { xs: "44px", lg: "56px" },
                    justifyContent: "initial",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    "&:hover": {},
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flex: "1",
                      color: "#FFFFFF",
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        color: activeTab === menuItem?.id ? "black" : "white",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            activeTab === menuItem?.id ? "black" : "#FFFFFF",
                          width: "100%",
                          fontSize: "15px",
                          zIndex: 999,
                        }}
                      >
                        {menuItem.text}
                      </Typography>
                    </ListItemText>
                  </Box>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      width: "fit-content",
                    }}
                  >
                    <ArrowRightIcon
                      color={activeTab === menuItem?.id ? "black" : "white"}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          })}
        </Box>
      ) : (
        <>
          {[
            {
              id: 1,
              text: "Profile",
              badgeText: null,
            },
            {
              id: 2,
              text: "Security",
              badgeText: null,
            },
            {
              id: 3,
              text: "Membership",
              badgeText: null,
            },
          ].map((menuItem: any) => {
            return (
              <ListItem
                onClick={() => {
                  setActiveTab(menuItem?.id);
                }}
                key={menuItem?.id}
                sx={{
                  paddingX: "0px",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  bgcolor:
                    activeTab === menuItem?.id
                      ? "primary.main"
                      : "rgba(255, 255, 255, 0.06)",
                  boxShadow:
                    activeTab === menuItem?.id
                      ? "none"
                      : "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                  "&.MuiListItem-root": {
                    paddingY: "0px !important",
                  },
                }}
              >
                <ListItemButton
                  disableRipple
                  sx={{
                    height: "56px",
                    justifyContent: "initial",
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    "&:hover": {},
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flex: "1",
                      color: "#FFFFFF",
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        color: activeTab === menuItem?.id ? "black" : "white",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            activeTab === menuItem?.id ? "black" : "#FFFFFF",
                          width: "100%",
                          fontSize: "15px",
                          zIndex: 999,
                        }}
                      >
                        {menuItem.text}
                      </Typography>
                    </ListItemText>
                  </Box>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      width: "fit-content",
                    }}
                  >
                    <ArrowRightIcon
                      color={activeTab === menuItem?.id ? "black" : "white"}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          })}
        </>
      )}
    </List>
  );
};

export default SettingsNavigationListItems;
