import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPaymentDetails from "./AddPaymentDetails";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import PaymentCard from "./PaymentCard";

type Props = {};

const Payment = (props: Props) => {
  const [savedCardsList, setSaveCardsList] = useState<any>([]);

  useEffect(() => {
    // const list = [
    //   {
    //     id: 1,
    //     cardNumber: "1234 1234 1234 1234",
    //     expiry: "22/07",
    //     primary: true,
    //   },
    //   {
    //     id: 2,
    //     cardNumber: "1234 1234 1234 1234",
    //     expiry: "22/07",
    //     primary: false,
    //   },
    // ];
    // setSaveCardsList(list);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "48px 56px",
        minWidth: "460px",
        maxWidth: "460px",
        borderRadius: "14px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        gap: "24px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontWeight: "600" }}
          variant="subtitle1"
        >
          Add new card
        </Typography>
        <Typography sx={{ color: "rgba(255, 255, 255, 0.5)" }} variant="body1">
          Add new card for future purchases and enable a more seamless shopping
          experience.
        </Typography>
      </Box>

      {/* *****Seperator***** */}
      <Box
        sx={{
          flex: 1,
          width: "100%",
          height: "1px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      />

      {/* -----Card section----- */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        {savedCardsList?.length > 0 ? (
          <>
            {savedCardsList?.map((card: any, index: number) => {
              return <PaymentCard details={card} index={index + 1} />;
            })}
            <ButtonPrimary onClick={() => {}} label="Add a new card" />
          </>
        ) : (
          <AddPaymentDetails />
        )}
      </Box>
    </Box>
  );
};

export default Payment;
