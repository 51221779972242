import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import { Box, Typography } from "@mui/material";
import Search from "../Search";
import DefaultLoading from "../../Loading/DefaultLoading";

interface DropdownProps {
  className?: string;
  classDropdownHead?: string;
  classDropdownValueText?: string;
  classDropdownLabel?: string;
  classDropdownActiveLable?: string;
  value?: any;
  setValue?: any;
  options?: any;
  label?: string;
  tooltip?: any;
  small?: any;
  upBody?: any;
  [key: string]: any;
  required?: boolean;
  searchDropdown?: boolean;
  dropdownStyles?: React.CSSProperties;
  dropdownBodyStyles?: React.CSSProperties;
  usedFor?: string;
  dropdownOptionsBodyStyles?: React.CSSProperties;
  dropdownOptionStyles?: React.CSSProperties;
  loading?: boolean;
  borderVisiblity?: string;
  focus?: boolean;
}
const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  classDropdownValueText,
  classDropdownActiveLable,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  required,
  dropdownStyles,
  searchDropdown,
  dropdownBodyStyles,
  dropdownOptionsBodyStyles,
  dropdownOptionStyles,
  usedFor,
  loading,
  borderVisiblity,
  focus,
  ...props
}: DropdownProps) => {
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const handleClick = async (value: any, index: any) => {
    if (value?.id) {
      if (value?.title === "None") {
        setValue("");
      } else {
        setValue([value]);
      }
    } else {
      if (value === "None") {
        setValue("");
      } else {
        setValue(value);
      }
    }
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <Box className={cn(styles.label, classDropdownLabel)}>
          <Box sx={{ display: "flex", gap: "2px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                letterSpacing: "0.48px",
              }}
              variant="body1"
            >
              {label}{" "}
            </Typography>
            {required && <Typography sx={{ color: "red" }}>*</Typography>}
          </Box>
        </Box>
      )}
      <Box
        sx={{ ...dropdownStyles }}
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <Box
          sx={{
            // border: borderVisiblity === "hide" ? "none" : "1.6px solid #E9EAEC",
            ":focus": {
              outline: "none",
            },
          }}
          tabIndex={0}
          className={cn(styles.head, classDropdownHead)}
          onClick={() =>
            props.disabled ? <></> : setTimeout(() => setVisible(!visible), 120)
          }
        >
          <Box
            sx={{
              ...props.labelHeadStyle,
            }}
            className={cn(
              styles.selection,
              classDropdownActiveLable,
              !value && props.classDropdownPlaceholder
            )}
          >
            <Typography
              sx={{ color: "white" }}
              className={cn(
                "capitalize",
                styles.selectionText,
                classDropdownValueText
              )}
              id={`dropdown|${label ?? ""}`}
              variant="body2"
              data-dropdown={label + "|" + value?.toString() || ""}
            >
              {value[0]?.title === undefined && value === ""
                ? props.placeholder && props.placeholder
                : value[0]?.title
                ? value[0]?.title
                : value}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: searchDropdown ? "16px" : "20px",
            zIndex: "100 !important",
            overflow: "hidden !important",
            ...dropdownBodyStyles,
          }}
          className={cn(
            styles.body,
            { [styles.bodyUp]: upBody },
            props?.showScroll ? "" : "HideScrollbar"
          )}
        >
          {searchDropdown && (
            <Search
              style={{
                padding: "12px 12px 12px 43px",
                fontSize: "14px",
                minWidth: "auto",
                backgroundColor: "#ECECEC",
                height: "48px",
              }}
              value={searchText}
              searchFormStyles={{
                minWidth: "auto !important",
                marginBottom: "8px",
              }}
              placeholder="Search name"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          )}
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "42px",
                  height: "42px",
                }}
              >
                <DefaultLoading />
              </Box>
            </Box>
          ) : (
            <Box
              className={props?.showScroll ? "" : "HideScrollbar"}
              sx={{
                maxHeight: "220px",
                overflowY: "scroll",
                ...dropdownOptionsBodyStyles,
              }}
            >
              {options?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingY: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                    variant="body2"
                  >
                    No options to select
                  </Typography>
                </Box>
              ) : (
                options
                  ?.filter((element: any) => {
                    if (element !== undefined) {
                      if (element?.title) {
                        const searchFilterValues =
                          searchText === "" ||
                          element?.title
                            ?.toLowerCase()
                            ?.includes(searchText?.toLowerCase());

                        return searchFilterValues;
                      } else {
                        const searchFilterValues =
                          searchText === "" ||
                          element
                            ?.toLowerCase()
                            ?.includes(searchText?.toLowerCase());
                        return searchFilterValues;
                      }
                    }
                  })
                  ?.map((x: any, index: any) => {
                    return (
                      <Box
                        sx={{
                          paddingX: searchDropdown ? "4px" : "0px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingTop: "12px",
                            paddingX: searchDropdown ? "12px" : "12px",
                            ...dropdownOptionStyles,
                          }}
                          className={cn(styles.option, {
                            [styles.selectioned]: x === value,
                          })}
                          onClick={() => {
                            x?.subTitle
                              ? handleClick(
                                  [
                                    {
                                      id: x?.id,
                                      title: x?.title,
                                      subTitle: x?.subTitle,
                                    },
                                  ],
                                  index
                                )
                              : x.title
                              ? handleClick(
                                  [{ id: x?.id, title: x?.title }],
                                  index
                                )
                              : handleClick(x, index);
                          }}
                          key={index}
                          id={x?.id}
                        >
                          <Typography
                            color={
                              x?.title
                                ? x?.title === value[0]?.title
                                  ? "primary.main"
                                  : "#FFFFFF"
                                : x === value
                                ? "primary.main"
                                : "#FFFFFF"
                            }
                            fontWeight={"500"}
                            variant="body1"
                            sx={{ textAlign: "left" }}
                          >
                            {x?.title ? x?.title : x?.split("|")[0]}
                          </Typography>

                          {x?.subTitle && (
                            <Typography
                              color={
                                x?.subTitle
                                  ? x?.subTitle === value[0]?.subTitle
                                    ? "primary.main"
                                    : "#FFFFFF"
                                  : "#FFFFFF"
                              }
                              fontWeight={"500"}
                              variant="body2"
                              sx={{ textAlign: "left" }}
                            >
                              {x?.subTitle}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })
              )}
            </Box>
          )}
        </Box>
      </Box>
    </OutsideClickHandler>
  );
};

export default Dropdown;
