import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  const PrivacyPolicyContent = [
    {
      title: "Licensing agreement",
      paragraphs: [
        `Here is what our UI8 Lisence allows for each item available for purchase or download on UI8.net.
         If you are purchasing for multiple individuals in your team,
          please support the authors and add the appropriate team size license to your cart during checkout.`,

        `The Ul8 License grants the user an ongoing, non-exclusive, worldwide license to utilize the digital work ("Item").
          You are licensed to use the Item to create unlimited end projects for yourself or for your clients 
          and the end projects may be sold, licensed, sub-licensed or freely distributed.`,
      ],
    },
    {
      title: "What you can do:",
      paragraphs: [
        `Create end products for clients or personal projects.`,
        `License, sub-license and make any number of copies of end projects for personal or client engagements.`,
        `You may modify or manipulate the Item. You may combine the Item with other works and make a derivative work from it. The resulting works are subject to the terms of this license.`,
        `This is a 'multi-use' license, which means you may use an Item multiple times, in multiple projects.`,
      ],
    },
    {
      title: "What you CANNOT do:",
      paragraphs: [
        `Re-distribute the Item as a stock image or it's source files, regardless of any modifications,
         under any circumstances. In other words, you cannot replicate or make modifications to the item and sell
        it on U18 or other marketplaces as your own, even if the source files are not included.`,
        `Make a theme, template or derivative work of any product to sell on any marketplace.`,
      ],
    },
    {
      title: "Sample end products:",
      paragraphs: [
        `End Products include but are not limited to: Commercial or personal websites, mobile apps, 
        web apps, games, illustrations, wireframes, presentations and videos.`,
      ],
    },
    {
      title: "Other licensing terms:",
      paragraphs: [
        `For some Items, a component of the Item will be sourced by the author from elsewhere and different
        license terms may apply to the component, such as someone else's license or an open source or creative commons license.
        If so, the component will be identified by the author in the Item's description page or in the Item's downloaded files.
        The other license will apply to that component instead of this license. This license will apply to the rest of the Item.`,
        `The author of the Item retains ownership of the Item but grants you the license on these terms.
        This license is between the author of the Item and you. Ul Wiki.net(UlWiki) is not a party to this license 
        or the one giving you the license.`,
      ],
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "40px", md: "80px" },
          alignItems: "center",
          gap: { xs: "32px", sm: "60px" },
          width: "100%",
          maxWidth: "862px",
        }}
      >
        {/* -------Header Section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start", sm: "center" },
            textAlign: { xs: "start", sm: "center" },
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              // background: "linear-gradient(79deg, #FFFFFF 0%, #303030 100%)",
              // WebkitBackgroundClip: "text",
              // WebkitTextFillColor: "transparent",
              fontSize: "32px !important",
            }}
            variant="h3"
          >
            Privacy Policy
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              mt: "8px",
            }}
          >
            Please take the time to thoroughly review the crucial information
            regarding our licensing terms of use and other policies provided on
            this page.
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ color: "primary.main", fontWeight: "700" }}
          >
            Last updated February 20, 2023
          </Typography>
        </Box>

        {/* -------Content section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "24px", sm: "32px" },
          }}
        >
          {PrivacyPolicyContent?.map((section: any, index: number) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                style={{
                  marginBottom: "12px",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {section.title}
              </Typography>
              {section?.paragraphs?.map((paragraph: string, pIndex: number) => (
                <Typography
                  variant="subtitle1"
                  key={pIndex}
                  style={{
                    marginBottom:
                      pIndex === section.paragraphs.length - 1 ? "0" : "24px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    lineHeight: "24px",
                  }}
                >
                  {paragraph}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
