import { Box, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../../../shared/components/TextInput";
import SwitchButton from "../../../../shared/components/Switch/CustomSwitch";

type Props = {
  details: any;
  index: number;
};

const PaymentCard = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
          }}
          variant="body1"
        >
          Card {props?.index}
        </Typography>
        <Typography
          sx={{
            color: "white",
          }}
          variant="body1"
        >
          Remove
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          padding: "16px",
          borderRadius: "14px",
          boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
        }}
      >
        <Box>
          <TextInput
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
            }}
            label="Card Number"
            placeholder="1234 1234 1234"
          />
        </Box>
        <Box sx={{ display: "flex", flex: 1, gap: "12px" }}>
          <TextInput
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D !important",
            }}
            inputWrapStyle={{
              minWidth: "auto !important",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
            }}
            label="Expiry"
            type="date"
            placeholder="MM/YY"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              flex: 1,
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
              }}
              variant="body1"
            >
              Set as primary
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "14px",
                boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                bgcolor: "rgba(255, 255, 255, 0.06)",
                height: "54px",
                paddingLeft: "16px",
                paddingRight: "14px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontWeight: "400",
                }}
                variant="body1"
              >
                Default
              </Typography>
              <SwitchButton switchWidth={26} switchHeight={16} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentCard;
