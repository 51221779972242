import { Box, Typography } from "@mui/material";
import React from "react";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import SwitchButton from "../../../../shared/components/Switch/CustomSwitch";

type Props = {};

const Notifications = (props: Props) => {
  const notificationsList = [
    {
      title: "Receive purchase receipts by email.",
      active: false,
    },
    {
      title: "Price Drops",
      active: false,
    },
    {
      title: "New Password",
      active: false,
    },
    {
      title: "Platform News and Updates",
      active: false,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "48px 56px",
        minWidth: "460px",
        maxWidth: "460px",
        borderRadius: "14px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        gap: "32px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontWeight: "600" }}
          variant="subtitle1"
        >
          Notifications
        </Typography>
        <Typography sx={{ color: "rgba(255, 255, 255, 0.5)" }} variant="body1">
          Stay informed about your account activity and purchases
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
          }}
        >
          {notificationsList?.map((item: any, index: number) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "15px 19px 15px 16px",
                  borderBottom:
                    index !== notificationsList?.length - 1
                      ? "2px solid #3D3D3D"
                      : "",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                  }}
                  variant="body1"
                >
                  {item?.title}
                </Typography>

                <SwitchButton
                  //  checked={}
                  //  value={}
                  //  onChange={(event: any) =>
                  //  }
                  //  color="primary"
                  switchWidth={24}
                  switchHeight={16}
                  //  defaultChecked
                />
              </Box>
            );
          })}
        </Box>

        <ButtonPrimary label="Save" />
      </Box>
    </Box>
  );
};

export default Notifications;
