import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import TextInput from "../shared/components/TextInput";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const ResetPasswordDialog: React.FC<Props> = (props) => {
  // const { setActiveSignupDialog, setActiveLoginDialog } = useGlobalContext();
  const [email, setEmail] = useState<string>("");
  // Error handling states
  const [error, setError] = useState<string>();

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              padding: "42px",
              height: "fit-content",
              width: { xs: "100%", sm: "488px", lg: "488px" },
              maxWidth: { xs: "100%", sm: "488px", lg: "488px" },
              maxHeight: "907px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Dialog content */}
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
                cursor: "pointer",
                color: "white",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "12px",
                  textAlign: "center",
                  width: "100%",
                  mb: "32px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                  }}
                  variant="h3"
                >
                  Forgot your password?
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  variant="subtitle1"
                >
                  No worries! Just enter your email address below, and we'll
                  send you a link to reset your password.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "32px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      borderRadius: "12px",
                      maxWidth: "480px",
                    }}
                  >
                    <TextInput
                      lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                      label="Email Address"
                      placeholder="Enter Your Email/ Employee ID"
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                    />
                    <Typography
                      className="err_field"
                      id="loginIdNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Button
                    id="signinButton"
                    sx={{
                      width: "100%",
                      height: "56px",
                      textAlign: "center",
                      textTransform: "none",
                      bgcolor: "primary.main",
                      color: "#FFFFFF",
                      borderRadius: "16px",
                      paddingY: "16px",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    onClick={() => {}}
                  >
                    <Typography
                      sx={{ color: "text.primary", fontWeight: "700" }}
                      variant="subtitle1"
                    >
                      {"Send Reset Link"}
                    </Typography>
                  </Button>
                  {error && (
                    <Typography
                      sx={{ width: "100%" }}
                      fontWeight={"400"}
                      variant="caption"
                      color="#E03137"
                    >
                      {error}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ResetPasswordDialog;
