import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import Avatar from "./components/Avatar";
import {
  UiWikiAvt1,
  UiWikiAvt10,
  UiWikiAvt11,
  UiWikiAvt12,
  UiWikiAvt13,
  UiWikiAvt14,
  UiWikiAvt15,
  UiWikiAvt16,
  UiWikiAvt17,
  UiWikiAvt18,
  UiWikiAvt19,
  UiWikiAvt2,
  UiWikiAvt20,
  UiWikiAvt21,
  UiWikiAvt22,
  UiWikiAvt23,
  UiWikiAvt24,
  UiWikiAvt25,
  UiWikiAvt26,
  UiWikiAvt27,
  UiWikiAvt28,
  UiWikiAvt29,
  UiWikiAvt3,
  UiWikiAvt30,
  UiWikiAvt31,
  UiWikiAvt32,
  UiWikiAvt33,
  UiWikiAvt34,
  UiWikiAvt4,
  UiWikiAvt5,
  UiWikiAvt6,
  UiWikiAvt7,
  UiWikiAvt8,
  UiWikiAvt9,
} from "../../../../assets/images/image";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  activeId: any;
};

const AvatarDialog: React.FC<Props> = (props) => {
  const [selectedAvatar, setSeletectedAvatar] = useState("");

  const avatarsList = [
    { key: "UiWikiAvt1", value: UiWikiAvt1 },
    { key: "UiWikiAvt2", value: UiWikiAvt2 },
    { key: "UiWikiAvt3", value: UiWikiAvt3 },
    { key: "UiWikiAvt4", value: UiWikiAvt4 },
    { key: "UiWikiAvt5", value: UiWikiAvt5 },
    { key: "UiWikiAvt6", value: UiWikiAvt6 },
    { key: "UiWikiAvt7", value: UiWikiAvt7 },
    { key: "UiWikiAvt8", value: UiWikiAvt8 },
    { key: "UiWikiAvt9", value: UiWikiAvt9 },
    { key: "UiWikiAvt10", value: UiWikiAvt10 },
    { key: "UiWikiAvt11", value: UiWikiAvt11 },
    { key: "UiWikiAvt12", value: UiWikiAvt12 },
    { key: "UiWikiAvt13", value: UiWikiAvt13 },
    { key: "UiWikiAvt14", value: UiWikiAvt14 },
    { key: "UiWikiAvt15", value: UiWikiAvt15 },
    { key: "UiWikiAvt16", value: UiWikiAvt16 },
    { key: "UiWikiAvt17", value: UiWikiAvt17 },
    { key: "UiWikiAvt18", value: UiWikiAvt18 },
    { key: "UiWikiAvt19", value: UiWikiAvt19 },
    { key: "UiWikiAvt20", value: UiWikiAvt20 },
    { key: "UiWikiAvt21", value: UiWikiAvt21 },
    { key: "UiWikiAvt22", value: UiWikiAvt22 },
    { key: "UiWikiAvt23", value: UiWikiAvt23 },
    { key: "UiWikiAvt24", value: UiWikiAvt24 },
    { key: "UiWikiAvt25", value: UiWikiAvt25 },
    { key: "UiWikiAvt26", value: UiWikiAvt26 },
    { key: "UiWikiAvt27", value: UiWikiAvt27 },
    { key: "UiWikiAvt28", value: UiWikiAvt28 },
    { key: "UiWikiAvt29", value: UiWikiAvt29 },
    { key: "UiWikiAvt30", value: UiWikiAvt30 },
    { key: "UiWikiAvt31", value: UiWikiAvt31 },
    { key: "UiWikiAvt32", value: UiWikiAvt32 },
    { key: "UiWikiAvt33", value: UiWikiAvt33 },
    { key: "UiWikiAvt34", value: UiWikiAvt34 },
  ];

  const handleSelectAvatar = (data: any) => {
    setSeletectedAvatar(data);
  };

  const handleSaveAvatar = () => {
    props?.handleDialog(selectedAvatar);
  };

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            minWidth: "100%",
            maxWidth: "956px",
            width: "100%",
            maxHeight: "642px",
            padding: "40px 40px 40px 80px",
            height: "auto",
            bgcolor: "#1B1B1B",
            borderRadius: "24px",
            border: "1px solid #3D3D3D",
            cursor: "default",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "@media screen and (max-width: 1000px)": {
              maxWidth: "80% !important",
              width: "100%",
              padding: "64px 52px 64px 52px",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              width: "100%",
              maxWidth: "796px",
            }}
          >
            Select an avatar
          </Typography>
          <CloseSharp
            onClick={(e) => {
              e.stopPropagation();
              props?.handleDialog();
            }}
            sx={{
              position: "absolute",
              right: "40px",
              top: "40px",
              cursor: "pointer",
              color: "white",
            }}
          />
          {/* Avatars List*/}
          <Box
            sx={{
              maxWidth: "796px",
              display: "flex",
              flexWrap: "wrap",
              gridAutoFlow: "row dense",
              minWidth: "796px",
              width: "100%",
              overflowY: "scroll",
              maxHeight: "380px",
              mb: "60px",
              pt: "56px",
              boxSizing: "border-box",
              gridAutoRows:
                "var(--breakpoint-grid_auto-rows, minmax(min-content,max-content) )",
              gridGap:
                "var(--breakpoint-grid_row-gap,40) var(--breakpoint-grid_column-gap,60)",
              gridTemplateColumns:
                "var(--breakpoint-grid_column-template, repeat(var(--breakpoint-grid_columns,1),minmax(0,1fr)) )",
              "--breakpoint-grid_row-gap": "40px",
              "--breakpoint-grid_column-gap": "60px",
              "@media screen and (min-width: 1880px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1700px) and (max-width: 1900px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1440px) and (max-width: 1700px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1000px) and (max-width: 1440px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 700px) and (max-width: 1000px)": {
                display: "grid !important",
                maxWidth: "100%",
                minWidth: "100%",
                gridTemplateColumns: "repeat(3, 1fr) !important",
              },
              "@media screen and (max-width: 700px)": {
                display: "grid !important",
                maxWidth: "100%",
                minWidth: "100%",
                gridTemplateColumns: "repeat(2, 1fr) !important",
              },
            }}
          >
            {avatarsList?.map((avatar) => {
              return (
                <Avatar
                  selectedAvatar={selectedAvatar}
                  activeId={props?.activeId}
                  handleSelectAvatar={handleSelectAvatar}
                  data={avatar}
                />
              );
            })}
          </Box>

          {/* -----Action buttons container----- */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <ButtonPrimary
              onClick={() => {
                handleSaveAvatar();
              }}
              sx={{ width: "201px", height: "52px" }}
              label="Save"
            />
            <ButtonSecondary
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{ width: "201px", height: "52px" }}
              label="Cancel"
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default AvatarDialog;
