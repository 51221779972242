import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../../../../shared/components/TextInput";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";

type Props = {};

const AddPaymentDetails = (props: Props) => {
  const [cardName, setCardName] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<number>();
  const [expiry, setExpiry] = useState<any>("");
  const [CVC, setCVC] = useState<any>("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          color: "white",
        }}
        variant="subtitle1"
      >
        Card information
      </Typography>

      <Box>
        <TextInput
          inputStyles={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
          }}
          lableStyles={{
            fontSize: "16px !important",
            fontWeight: "600",
          }}
          value={cardName}
          onChange={(e: any) => {
            setCardName(e.target.value);
          }}
          label="Name on card"
          placeholder="Name on card"
        />
      </Box>

      <Box>
        <TextInput
          inputStyles={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
          }}
          lableStyles={{
            fontSize: "16px !important",
            fontWeight: "600",
          }}
          value={cardNumber}
          onChange={(e: any) => {
            setCardNumber(e.target.value);
          }}
          label="Card Number"
          placeholder="1234 1234 1234"
        />
      </Box>

      <Box sx={{ display: "flex", flex: 1, gap: "12px" }}>
        <TextInput
          inputStyles={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D !important",
          }}
          inputWrapStyle={{
            minWidth: "auto !important",
          }}
          lableStyles={{
            fontSize: "16px !important",
            fontWeight: "600",
          }}
          value={expiry}
          onChange={(e: any) => {
            setExpiry(e.target.value);
          }}
          label="Expiry"
          type="date"
          placeholder="MM/YY"
        />

        <TextInput
          inputStyles={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
          }}
          inputWrapStyle={{
            minWidth: "auto !important",
          }}
          lableStyles={{
            fontSize: "16px !important",
            fontWeight: "600",
          }}
          value={CVC}
          onChange={(e: any) => {
            setCVC(e.target.value);
          }}
          label="CVC"
          placeholder="CVC"
        />
      </Box>

      {/* -----Action buttons container----- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          mt: "8px",
        }}
      >
        <ButtonSecondary onClick={() => {}} sx={{ flex: 1 }} label="Cancel" />
        <ButtonPrimary onClick={() => {}} sx={{ flex: 1 }} label="Save" />
      </Box>
    </Box>
  );
};

export default AddPaymentDetails;
