import { Box, Typography } from "@mui/material";
import { TickCircleIconSvg } from "../assets/icons/TickCircleIconSvg";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../shared/components/button/Buttons";
import MembershipDialog from "../pages/setting/tabs/membership/membershipDialog/MembershipDialog";
import { useState } from "react";

type Props = {
  activePlan: string;
  setMembershipDialog: any;
  setConfirmCancelMembershipDialog: any;
};

const MembershipCard = (props: Props) => {
  const { activePlan, setConfirmCancelMembershipDialog } = props;
  const [membershipDialog, setMembershipDialog] = useState(false);

  let title = "";
  let amount = "";

  if (activePlan === "MONTHLY") {
    title = "Regular Monthly Membership";
    amount = "10/month";
  } else if (activePlan === "YEARLY") {
    title = "Annual Membership";
    amount = "6/month*billed yearly";
  } else if (activePlan === "LIFETIME") {
    title = "Lifetime Plan";
    amount = "250";
  } else {
  }

  const handleCloseMembershipDialog = () => {
    setMembershipDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        borderRadius: "14px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        maxWidth: { xs: "100%", sm: "428px" },
        minWidth: { xs: "100%", sm: "428px" },
        gap: "24px",
      }}
    >
      {/* -----Price section----- */}
      {membershipDialog && (
        <MembershipDialog
          handleDialog={handleCloseMembershipDialog}
          activeDialog={membershipDialog}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Typography
            sx={{ fontWeight: "600", color: "primary.main" }}
            variant="body1"
          >
            {title}
          </Typography>
        </Box>

        <Typography
          sx={{
            fontSize: "58px",
            color: "primary.main",
            fontWeight: "600",
            display: "flex",
            alignItems: "flex-start",
            gap: "6px",
          }}
          variant="subtitle1"
        >
          $
          <span
            style={{
              fontSize: "57px",
              lineHeight: "100%",
              color: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "flex-end",
              gap: "6px",
            }}
          >
            {amount?.split("/")[0]}
            <span
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "100%",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              {`/${amount?.split("/")[1]}`}
            </span>
          </span>
        </Typography>
      </Box>

      {/* *****Seperator***** */}
      <Box
        sx={{
          flex: 1,
          height: "1px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      />

      {/* ------Pricing Details------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
          Components
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Premium component 40+
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
          Apps & Tools
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />{" "}
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Chrome Extension
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />{" "}
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Figma Plugin
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
          Other
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />{" "}
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Priority support
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />{" "}
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Discount to partner tools
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "8px",
        }}
      >
        <ButtonPrimary
          label="Upgrade"
          onClick={() => {
            setMembershipDialog(true);
          }}
        />
        <ButtonSecondary
          onClick={() => {
            setConfirmCancelMembershipDialog(true);
          }}
          label="Cancel Membership"
        />
      </Box>
    </Box>
  );
};

export default MembershipCard;
