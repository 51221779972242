import { Box, TablePagination } from "@mui/material";
import React, { useState } from "react";
import LayoutTable from "./layout/Table";

type Props = {};
const tableRef = React.createRef<any>();

const Billing = (props: Props) => {
  const columnItems = [
    { id: "Product", label: "Product" },
    { id: "Invoice Date", label: "Invoice Date" },
    { id: "Amount", label: "Amount" },
    { id: "Date Edited", label: "Date Edited" },
  ];

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productList, setProductList] = useState<any>([]);

  const handleRefreshData = () => {
    setPage(0);
    // fetchBills({
    //   page: page,
    //   pageSize: rowsPerPage,
    // });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    // const newFilterList = {
    //   page: newPage + 1,
    //   pageSize: rowsPerPage,
    // };
    // fetchBills(newFilterList);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    tableRef.current && tableRef.current.scrollIntoView();
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: "1000px",
      }}
    >
      <LayoutTable
        loading={productsLoading}
        rows={productList}
        columns={columnItems}
        handleRefreshData={handleRefreshData}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TablePagination
          labelRowsPerPage="Showing"
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            style: {
              fontWeight: "700",
            },
          }}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              fontWeight: "700",
              color: "rgba(255, 255, 255, 0.24)",
              marginRight: "5px",
            },
            "& .MuiTablePagination-displayedRows": {
              color: "rgba(255, 255, 255, 0.24)",
              fontWeight: "600",
            },
            "& .MuiButtonBase-root": {
              boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
              cursor: "pointer !important",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiTablePagination-actions": {
              display: "flex",
              gap: "12px !important",
              flex: 1,
              justifyContent: "flex-end",
            },
            "&.MuiTablePagination-root": {
              width: "100%",
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            },
            "& .MuiTablePagination-spacer": {
              flex: 0,
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              flex: 1,
              display: "flex",
              paddingLeft: 0,
            },
            "& .MuiTablePagination-select": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              display: "none",
            },
          }}
          // rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={productList?.totalComponents}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default Billing;
