import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmCancelMembershipDialog from "./cancelMembership/ConfirmCancelMembershipDialog";
import CancelMembershipDialog from "./cancelMembership/CancelMembershipDialog";
import { getUserActiveSubscription } from "../../../../services/component";
import { getUserIdFromCookies } from "../../../../utils/extensions";
import MembershipCard from "../../../../components/MembershipCard";

type Props = {
  data: any;
  handleRefresh: any;
  setMembershipDialog: any;
};

const ActiveMembershipCard = (props: Props) => {
  const userId = getUserIdFromCookies();
  const { setMembershipDialog } = props;
  const [activePlan, setActivePlan] = useState<any>({});

  const [confirmCancelMembershipDialog, setConfirmCancelMembershipDialog] =
    useState<boolean>(false);
  const [activeCancelMembershipDialog, setActiveCancelMembershipDialog] =
    useState<boolean>(false);

  const handleCloseConfirmCancelMembershipDialog = (res?: any) => {
    if (res?.action === "CANCEL") {
      setActiveCancelMembershipDialog(true);
    }
    setConfirmCancelMembershipDialog(false);
  };

  const handleCloseCancelMembershipDialog = () => {
    setActiveCancelMembershipDialog(false);
    props?.handleRefresh();
  };

  const handleMembershipPlanActions = (res: any) => {
    if (res?.action === "CANCEL") {
      setConfirmCancelMembershipDialog(true);
    }
  };

  const getSubscriptions = async () => {
    await getUserActiveSubscription(userId)
      .then((res) => {
        setActivePlan(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {confirmCancelMembershipDialog && (
        <ConfirmCancelMembershipDialog
          handleDialog={handleCloseConfirmCancelMembershipDialog}
          activeDialog={confirmCancelMembershipDialog}
        />
      )}
      {activeCancelMembershipDialog && (
        <CancelMembershipDialog
          handleDialog={handleCloseCancelMembershipDialog}
          activeDialog={activeCancelMembershipDialog}
        />
      )}
      <MembershipCard
        setMembershipDialog={setMembershipDialog}
        setConfirmCancelMembershipDialog={setConfirmCancelMembershipDialog}
        activePlan={activePlan}
      />
    </Box>
  );
};

export default ActiveMembershipCard;
